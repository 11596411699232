import { BsFillTrash3Fill } from "react-icons/bs";
import CopyToClipboard from './CopyToClipboard';
import DeleteModal from './DeleteModal';
import React, { useEffect, useState } from 'react';
import { getInfo, GetSecuredContents, DeleteSecureContent, GetSharesForSecureContent } from "../utilities/calls";
import {  AiOutlineProfile } from "react-icons/ai";
import {  contentTypeList } from "../utilities/templates";
import AddressList from "./AddressList";
import { Link, useLocation } from "react-router-dom";
import { FaShareAlt, FaShareSquare } from "react-icons/fa";
import ShareContentModal from "./ShareContentModal";

export default function SecureContentList() {
    const [securedcontents, setSecuredcontents] = useState([]);
    const [showDeleteDlg, setShowDeleteDlg] = useState();
    const [currSpId, setCurrSpId] = useState();
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [contenttype, setContenttype] = useState("");
    const [sharedCounts, setSharedCounts] = useState({});
    const [showShareModal, setShowShareModal] = useState(false);
    const [selectedContent, setSelectedContent] = useState();
    const [addrCount, setAddrCount] = useState();
    let {state} = useLocation();
    
    useEffect(() => {
        setContenttype(state?.contenttype || localStorage.getItem(document.location.hostname + ".content.contenttype") || "");
        loadList();
    }, []);

    const saveContenttype = (val) => {
        localStorage.setItem(document.location.hostname + ".content.contenttype", val);
        setContenttype(val)
    }

    const loadList = () => {
        const individualid = getInfo("IndividualID");
        if (individualid === null) return;
        GetSecuredContents()
            .then((resp) => {
                setSecuredcontents(resp);
                const fetchCounts = async (contents) => {
                    const sharesPromises = contents.map((c) =>
                      GetSharesForSecureContent(c.id)
                        .then((response) => {
                          return { securedcontentid: c.id, count: response.length };
                        })
                        .catch((error) => {
                          console.error(`Error shares for ID ${c.id}:`, error);
                          setShowAlert(true);
                          setAlertText("Error fetching shares");
                        })
                    );
                    Promise.all(sharesPromises)
                      .then((details) => {
                        const cmap = {};
                        details.forEach(
                          (detail) => (cmap[detail.securedcontentid] = detail.count)
                        );
                        setSharedCounts(cmap);
                      })
                      .catch((error) => {
                        console.error("Error setting record details:", error);
                        setShowAlert(true);
                        setAlertText("Error setting record details");
                      });
                  };
                  if (resp && resp.length > 0) {
                    fetchCounts(resp);
                  }
            })
            .catch((error) => {
                console.error("Error in getSecureContent:", error);
                setShowAlert(true);
                setAlertText('Error loading data, please try again')
            });
    }

    
    const handleDeleteClick = (spid) => {
        setShowDeleteDlg(true);
        setCurrSpId(spid);
    }

    const deleteSC = () => {
        setShowDeleteDlg(false);

        DeleteSecureContent(currSpId, true).then(() => {
            setShowAlert(true);
            setAlertText("Secured Content was successfully deleted.");
            loadList();
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 2000);
        });
    }

    const handleShareClick= (sc) => {
        setSelectedContent(sc);
        setShowShareModal(true);
    }

    return (

        <div className='space-y-3 px-1 md:px-4 py-3 max-w-screen-xl mx-auto items-center'>
            {showAlert ? (
                <div
                    class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                    role="alert"
                    style={{ display: showAlert ? "block" : "none" }}
                >
                    <span class="text-sm">{alertText} </span>
                </div>
            ) : (
                <></>
            )}
            <DeleteModal confirmationText="Are you certain you wish to delete this secured content?" deleteLabel="Delete" onDeleteFn={deleteSC} onCancelFn={() => { setShowDeleteDlg(false) }} show={showDeleteDlg} />

            <h1 className="bold-text-input">Secure Content List</h1>
            {contenttype === "" ? <div className="text-small py-3 max-w-screen-xl mx-auto items-center">Choose content type. Add your data and safely share it with businesses, friends, or family.</div> : <></>}
            <div class="block w-auto " id="navbar-default">
                <ul class="font-medium flex mt-4  flex-row space-x-2 md:space-x-8 border-y-2 border-gray-700 ">
                    {contentTypeList.map(element => {
                        return <li>  <a onClick={(e) => { e.preventDefault(); saveContenttype(element.value); return false; }} id={"tab_" + element.value} href="#" class={contenttype === element.value ? "block py-2 px-3 rounded bg-transparent  p-0 text-blue-500" : "block py-2 px-3 rounded bg-transparent border-0 p-0 text-white hover:text-blue-500 "} >{element.name} {element.value === "address_locales" ? addrCount : Object.keys(securedcontents.filter(a => a.tags?.contenttype?.Value === element.value) || {}).length}</a>
                        </li>
                    })}
                </ul>
            </div> 
            <AddressList contenttype={contenttype} addressCount={setAddrCount} show={contenttype === "address_locales"}/>
            { contenttype === "" || contenttype === "address_locales"  ? <></> : <div className="px-4 py-3 max-w-screen-xl mx-auto items-center">
            <h1 className="bold-text-input">List of {contentTypeList.map((ele) => { if(contenttype === ele.value) return ele.name} )}</h1>
            <div style={{ marginTop: "20px" }}>
            <Link className="button-style hover:bg-555 mb-2" to='/addsecurecontent/' state={{"contenttype" : contenttype}}>
                <AiOutlineProfile className='inline-icon' />
                Add {contentTypeList.map((ele) => { if(contenttype === ele.value) return ele.name} )}
            </Link>
            <div style={{ marginTop: "20px" }}>
              <ul class="w-full divide-y divide-gray-700 py-3">

                    {securedcontents?.length > 0 ? securedcontents.filter(a => a.tags?.contenttype?.Value === contenttype).sort((a, b) => { return a.tags?.name?.Value?.toLowerCase().localeCompare(b.tags?.name?.Value?.toLowerCase()) }).map((sp, index) => {
                        const scount = sharedCounts[sp.id] 

                        return <li class="pb-3 sm:pb-4">

                            <div class="flex items-center space-x-4 rtl:space-x-reverse">

                                <div class="flex-1">
                                        <Link class="text-base font-semibold truncate text-blue-400" to={`/addsecurecontent/${sp.id}`} state={{"contenttype" : contenttype}}>

                                            {`${sp.tags?.name?.Value || sp.tags?.title?.Value || "No Name"}`}
                                        </Link>
                                    <span class="text-sm font-medium mx-2 px-2.5 py-0.5 rounded-full bg-gray-700 text-green-400 border border-green-400"> {`${scount}`} Shares </span>

                                </div>
                                
                                <div class="inline-flex items-center text-white">
                                    <button formMethod="dialog" formTarget="top" id="deleteSP" onClick={() => handleDeleteClick(sp.id)} className="bg-slate-333 py-1 hover:bg-555">
                                        <BsFillTrash3Fill title="Delete" className="inline-icon" />
                                    </button>
                                </div>
                            </div>
                            <p class="text-sm text-gray-400">
                                <span id={"sp_guid_" + index}>{sp.id}</span><CopyToClipboard elId={"sp_guid_" + index} />
                            </p>
                            <button formMethod="dialog" formTarget="top" id="sharecontent" onClick={() => handleShareClick(sp)} className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline py-1">
                             <FaShareAlt className="inline-icon"/>Share
                            </button>
                        </li>

                    })

                        : <li>No {contentTypeList.map((ele) => { if(contenttype === ele.value) return ele.name} )} found.</li>
                    }
                </ul>
            </div>
            </div></div>}

            {showShareModal &&    <ShareContentModal content={selectedContent} onClose={() => {setShowShareModal(false);}}/>} 


        </div>
    )

}