import React, { useEffect, useState, useLayoutEffect } from "react";
import { Link,useNavigate,useLocation } from "react-router-dom";
import {
  FetchIndividual,
  getInfo,
  GetSharesForAddress,DeleteAddress,
  } from "../utilities/calls";
import { AiOutlineProfile } from "react-icons/ai";
import { BsFillTrash3Fill } from "react-icons/bs";
import Login from "./Login";
import DeleteModal from "./DeleteModal";
import CopyToClipboard from "./CopyToClipboard";
import { FaShareAlt, FaShareSquare } from "react-icons/fa";
import ShareContentModal from "./ShareContentModal";


const AddressList = ({contenttype, addressCount, show}) => {
  
  const [individual, setIndividual] = useState({});
  const [sharedCounts, setSharedCounts] = useState({});
  const [userId, setUserId] = useState();
  const [showAlert, setShowAlert] = useState();
  const [alertText, setAlertText] = useState();
  const [showDeleteDlg, setShowDeleteDlg] = useState();
  const[confirmationText, setConfirmationText] = useState();
  const[currAddressId, setCurrAddressId] = useState();
  const individualid = getInfo("IndividualID");
  const [showShareModal, setShowShareModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState();

  const navigate = useNavigate();

  useLayoutEffect(() => {
    setUserId(getInfo("UserID"));
    setShowDeleteDlg(false);
  }, []);

  const GetAddressses = () => {

    FetchIndividual(individualid).then((individualData) => {
      // Set the retrieved individual in the state
      const alladdresses = individualData?.addresses || {};
      addressCount(Object.keys(alladdresses || {}).length)
      const fetchCounts = async (addresses) => {
        const sharesPromises = Object.keys(addresses).map((id) =>
          GetSharesForAddress(id)
            .then((response) => {
              return { addressid: id, count: response.length };
            })
            .catch((error) => {
              console.error(`Error shares for ID ${id}:`, error);
              setShowAlert(true);
              setAlertText("Error fetching shares");
            })
        );
        Promise.all(sharesPromises)
          .then((details) => {
            const cmap = {};
            details.forEach(
              (detail) => (cmap[detail.addressid] = detail.count)
            );
            setSharedCounts(cmap);
          })
          .catch((error) => {
            console.error("Error setting record details:", error);
            setShowAlert(true);
            setAlertText("Error setting record details");
          });
      };
      setIndividual(individualData);
      if (Object.keys(individualData?.addresses || {}).length > 0) {
        fetchCounts(alladdresses);
      }
    });
  
  }

  useEffect(() => {
    GetAddressses();
  }, []);

  const deleteAddress = () => {
    console.log(currAddressId)
    setShowDeleteDlg(false);
    const ownertoken = getInfo("Ownertoken");

    
    DeleteAddress(currAddressId, individualid, ownertoken)
    .then((response) => {
      setShowAlert(true);
      setAlertText("Address was successfully deleted." );
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 2000);
      GetAddressses();
    })
    .catch((error) => {
      console.error("Error deleting an address", error);
      setShowAlert(true);
      setAlertText("Error deleting an address.");
    });
  
  }

  const handleDeleteClick = (addressId) => {
    console.log(addressId)
    const scount = sharedCounts[addressId];

    setConfirmationText("Are you certain you wish to delete this address? It has been shared " + scount + " times. Deleting it will Remove those shares. Please confirm ...");
    setShowDeleteDlg(true);
    setCurrAddressId(addressId);
  }

  
  const handleShareClick = (addressId) => {
    //enter email to send address to 
    navigate(`/offerAddress/${addressId}`);
    
  }

  const handleShareContentClick= (addr) => {
    setSelectedAddress(addr)
    setShowShareModal(true);
}

  return userId ? (
    show ?
    <div className="px-1 md:px-4 py-3 max-w-screen-xl mx-auto items-center">
    <DeleteModal confirmationText={confirmationText} deleteLabel="Delete" onDeleteFn={deleteAddress} onCancelFn={()=>{setShowDeleteDlg(false)}} show={showDeleteDlg}/>

    <h1 className="bold-text-input">List of My Addresses</h1>
      {individual.id ? (
        
          <div style={{ marginTop: "20px" }}>
            <Link
              className=""
              to={{ pathname: "/AddAddressDetails" }}
              state={{ tags: individual.tags, previousPage: "/securedContents", "contenttype" : contenttype }}
            >
              <button className="button-style hover:bg-555">
                <AiOutlineProfile className="inline-icon" />
                Add New Address
              </button>
            </Link>
            <div style={{ marginTop: "20px" }}>
              <ul class="w-full divide-y divide-gray-700 py-3">
              {Object.keys(individual.addresses || {}).sort((a, b) => {return individual.addresses[a].tags?.atag?.Name.toLowerCase().localeCompare(individual.addresses[b].tags?.atag?.Name.toLowerCase())}).map((addressId, index) => {
                    const address = individual.addresses[addressId];
                    const scount = sharedCounts[addressId];
                    return <li class="py-3 sm:py-2">
                      
                       <div class="flex items-center space-x-4 rtl:space-x-reverse">
                          
                          <div class="flex-1">
                             <Link class="text-lg font-semibold truncate" to={`/address-details/${address.id}`} state={{"contenttype": contenttype, previousPage: '/securedContents'}}>
                            {`${address.tags?.atag?.Name || "No Name"}`}
                          </Link>
                             <span class="text-sm font-medium mx-2 px-2.5 py-0.5 rounded-full bg-gray-700 text-green-400 border border-green-400"> {`${scount}`} Shares </span>

                          </div>
                          <div class="flex-shrink-0 md:flex-1">
                           

                          </div>
                          <div class="inline-flex items-center text-white">
                          <button formMethod="dialog" formTarget="top" id="deleteAddress" onClick={() => handleDeleteClick(addressId)} className="bg-slate-333 py-1 hover:bg-555">
                            <BsFillTrash3Fill title="Delete" className="inline-icon" />
                          </button>
                          
                          </div>
                       </div>
                       <p class="py-1 text-sm text-gray-400">
                                <span id={"addr_guid_" +index }>{address.id}</span><CopyToClipboard elId={"addr_guid_" +index }/>
                             </p>
                             <button formMethod="dialog" formTarget="top" id="offerAddress" onClick={() => handleShareClick(addressId)} className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline py-1">
                             <FaShareSquare className="inline-icon"/>Send Address
                          </button>
                          <button formMethod="dialog" formTarget="top" id="offerAddress" onClick={() => handleShareContentClick(address)} className="ps-2 text-blue-400 bg-slate-333 hover:bg-555 hover:underline py-1">
                             <FaShareAlt className="inline-icon"/>Share
                          </button>
                    </li>
                      
                  })
                }</ul>
            </div>
            {showShareModal &&    <ShareContentModal onClose={() => {setShowShareModal(false);}} address={selectedAddress}/>} 

          </div>
        
      ) : (
        <div>
          {showAlert ? (
            <div
              class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
              role="alert"
              style={{ display: showAlert ? "block" : "none" }}
            >
              <span class="text-sm">{alertText} </span>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      )}
    </div>
    : <></>
  ) : (
    <Login/>
  );
};

export default AddressList;
